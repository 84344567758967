import { createSlice } from '@reduxjs/toolkit'

export const routes = createSlice({
  name: 'routes',
  initialState: [],
  reducers: {
    addRoutes: (state, action) => {
      for (const route of action.payload) {
        if (!state.includes(route)) {
          state.push(route)
        }
      }
    }
  }
})

export const { addRoutes } = routes.actions

export default routes.reducer
