// ** React Imports
import { Link, Navigate, useNavigate } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { User, Power, MessageCircle, ArrowLeft } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, Button } from 'reactstrap'

// ** Default Avatar Image
import BackOfficeAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { SpinnerLoader, user$ } from '@Intelli/utilities'
import { parseName } from '@src/utility/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faWallet } from '@fortawesome/pro-solid-svg-icons'
import { setUser } from '@src/redux/user'
import { setuserInfo } from '@src/redux/userInfo'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
const CustomUserDropdown = () => {
  const { t } = useTranslation()
  const API = new BackOfficeAPI()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  /* const {
    data: userData,
    isError: userHasError,
    isLoading: userIsLoading
  } = useQuery(['user'], () => API.getUser(), {
    onSuccess: (dt) => {
      dispatch(setuserInfo(dt))
    }
  }) */

  const user = useSelector(state => state.userInfo)

  const {
    data: backofficeUser,
    isError: backofficeUserHasError,
    isLoading: backofficeUserIsLoading
  } = useQuery(['backofficeUser'], () => API.getBackofficeUserInfo())

  const { mutate: logout } = useMutation(() => API.logout(), {
    onSuccess: () => {
      localStorage.clear()
      navigate('/')
    }
  })

  const logoutHandler = e => {
    e.preventDefault()
    logout()
  }

  const Dropdown = () => {
    if (backofficeUserIsLoading || backofficeUserHasError) {
      return <SpinnerLoader />
    }

    dispatch(setUser(backofficeUser))

    const { mutate: handleAdminWorkspaceChange } = useMutation(workspaceId => API.changeAdminWorkspace(workspaceId), {
      onSuccess: data => {
        localStorage.setItem('token', JSON.stringify(data.token.token))
        window.location = '/admin'
      }
    })

    const { mutate: handleContractorWorkspaceChange } = useMutation(
      workspaceId => API.changeContractorWorkspace(workspaceId),
      {
        onSuccess: data => {
          localStorage.setItem('token', JSON.stringify(data.token.token))
          window.location = '/wallet'
        }
      }
    )

    return (
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={e => e.preventDefault()}>
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name fw-bold">{parseName(user?.user?.first_name, user?.user?.last_name)}</span>
            <span className="user-status">{t(`roles.${backofficeUser.role}`)}</span>
          </div>
          {user.photo?.thumbnail ? (
            <Avatar img={user.photo?.photo} imgHeight="40" imgWidth="40" imgClassName="avatar-cover" />
          ) : (
            <Avatar
              content={parseName(user?.user?.first_name, user?.user?.last_name)}
              initials
              color="info"
              imgHeight="40"
              imgWidth="40"
            />
          )}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem tag={Link} to={`/backoffice/account-settings`}>
            <FontAwesomeIcon icon="user" className="me-75" />
            <span className="align-middle">{t('profile')}</span>
          </DropdownItem>
          {/* <DropdownItem divider /> */}
          {/* <DropdownItem tag={Link} to="/admin">
            <MessageCircle size={14} className="me-75" />
            Support
          </DropdownItem>
          <DropdownItem divider /> */}
          {user$.value.companiesCount > 1 && (
            <>
              {' '}
              <DropdownItem tag={Link} to="/lobby?auto=false">
                <ArrowLeft size={14} className="me-75" />
                {t('companies')}
              </DropdownItem>
            </>
          )}
          {(user$?.value?.company?.admin || user$.value.backoffice) && (
            <>
              <DropdownItem divider />
              {/*<DropdownItem text header>
                <span className="align-middle">{t('change_to')}</span>
              </DropdownItem> */}
            </>
          )}
          {user$.value?.company?.admin && (
            <DropdownItem
              tag={Button}
              className="rounded-0"
              onClick={() => handleAdminWorkspaceChange({ access_id: user$.value.company.admin.access_id })}
              color="link"
              block
            >
              <FontAwesomeIcon icon={faBuilding} className="me-75" />
              {t('admin')}
            </DropdownItem>
          )}
          <DropdownItem
            tag={Button}
            className="rounded-0"
            onClick={() => {
              if (user$.value?.company?.contractor)
                handleContractorWorkspaceChange({ contractor_id: user$.value.company.contractor.contractor_id })
              else window.location = '/wallet'
            }}
            color="link"
            block
          >
            <FontAwesomeIcon icon={faWallet} className="me-75" />
            {t('contractor')}
          </DropdownItem>
          <DropdownItem divider />
          {/* <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
                        <HelpCircle size={14} className="me-75" />
                        <span className="align-middle">FAQ</span>
                    </DropdownItem> */}
          <DropdownItem tag={Link} to="/" onClick={logoutHandler}>
            <FontAwesomeIcon icon="power-off" className="me-75" />
            <span className="align-middle">{t('logout')}</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
  if (token) {
    return <Dropdown />
  } else {
    return <Navigate to="/" />
  }
}

export default CustomUserDropdown
