import {
  BlueHeaderCard,
  JpText,
  JpOrdersBarChart,
  SpinnerLoader,
  JpCurrencyNumber,
  JpTableV1,
  JpAvatar,
  dateLocalizer
} from '@Intelli/utilities'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import MainInfoHeader from '@src/components/accounting/MainInfoHeader'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import BackOfficeAPI from '@src/services/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'


const HistoricDetail = () => {
  const API = new BackOfficeAPI()

  const { t } = useTranslation()
  const { invoice_id, client_id } = useParams()

  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [queries, setQueries] = useState('')
  const [sortBy, setSortBy] = useState('start_date__desc')


  const HeaderCard = () => {
    return (
      <div className="d-flex justify-content-between align-items-center col-12">
        <h4 className="text-white fw-bold mb-0">{t('Company')}</h4>
      </div>
    )
  }

  const {
    data: detailIndex,
    isError: detailIndexHasError,
    isLoading: detailIndexIsLoading
  } = useQuery(['detailIndex', invoice_id], () => API.historicIndex(invoice_id))

  const dataFromMainInfo = {
    logo: detailIndex?.client?.media?.location,
    name: detailIndex?.client?.name,
    country: detailIndex?.client?.address?.country,
    first_line: detailIndex?.client?.address?.first_line,
    second_line: '',
    email: detailIndex?.client?.email,
    phone: detailIndex?.client?.phone
  }

  const {
    data: invoicedChart,
    isError: invoicedChartHasError,
    isLoading: invoicedChartIsLoading
  } = useQuery(['invoicedChart', client_id], () => API.invoicedChart(client_id))

  const valuesChart = invoicedChart?.map(i => i.amount)
  const datesChart = invoicedChart?.map(i => moment(i.date).format('MMMM'))
  const transtaleDatesChart = datesChart?.map(i => t(`views.months.${i}`))


  const BodyCard = () => {
    return (
      <Row>
        <MainInfoHeader
          data={dataFromMainInfo}
          column={
            <Col className="ms-1 d-flex flex-column flex-lg-row">
              <div className="col-lg-8">
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('Payroll')}:</b>
                  </Col>
                  <Col>
                    {detailIndex?.payroll_balance ? <JpCurrencyNumber value={detailIndex?.payroll_balance} /> : '-'}
                  </Col>
                </Row>
                <Row className="d-flex mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.commissions')}:</b>
                  </Col>
                  <Col>
                    {detailIndex?.commission_balance ? (
                      <JpCurrencyNumber value={detailIndex?.commission_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.SASS')}:</b>
                  </Col>
                  <Col>{detailIndex?.saas_balance ? <JpCurrencyNumber value={detailIndex?.saas_balance} /> : '-'}</Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.SERVICES')}:</b>
                  </Col>
                  <Col>
                    {detailIndex?.service_balance ? <JpCurrencyNumber value={detailIndex?.service_balance} /> : '-'}
                  </Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.totalInvoiced')}:</b>
                  </Col>
                  <Col>
                    {detailIndex?.total_balance ? <JpCurrencyNumber value={detailIndex?.total_balance} /> : '-'}
                  </Col>
                </Row>
              </div>
              <JpOrdersBarChart
                title={t('views.accounting.totalBilled')}
                total={detailIndex?.total_balance}
                values={valuesChart}
                months={transtaleDatesChart}
                className={'p-0 ms-n1'}
              />
            </Col>
          }
        />
      </Row>
    )
  }

  const TableFooter = () => {
    return(
      <>
      <JpText type={'card-title'}>{t('views.clients.totalInvoiced')}</JpText>
      <span>:</span>
      <JpCurrencyNumber value={detailIndex?.total_balance} />
    </>
    )
  }

  const {
    data: historicDetailData,
    isError: historicDetailDataHasError,
    isLoading: historicDetailDataIsLoading
  } = useQuery(
    ['historicDetailData', invoice_id, currentPage, queries, limit, sortBy],
    () =>
      API.historicTable({
        query: queries,
        offset: currentPage,
        limit,
        order: sortBy,
        invoice_id: invoice_id
      }),
    { keepPreviousData: true }
  )

  const handlePageChange = ({ page, limit, query, sort }) => {
    setCurrentPage(page)
    setLimit(limit)
    setQueries(query)
    if (sort) setSortBy(sort)
  }

  const tabCols = [
    {
      id: 'avatar_column',
      name: '',
      sortable: false,
      compact: true,
      right: true,
      maxWidth: '50px',
      minWidth: '20px',
      cell: row => <JpAvatar icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} color={`success`} />
    },
    {
      name: t('views.clients.createClient.service'),
      sortable: 'name',
      minWidth: '200px',
      compact: true,
      cell: row => {
        return <p className='m-0'>{row?.name}{row?.code && <span className='ms-50'>{row?.code}</span>}</p>
      }
    },
    {
      name: t('views.clients.createClient.date'),
      sortable: 'date',
      allowOverflow: false,
      wrap: true,
      cell: row => {
        return (
          <>
            {row?.date ? (
              <JpText type={'span-table-date'}>{dateLocalizer.getIntlLongDate(row?.date)}</JpText>
            ) : (
              '-'
            )}
          </>
        )
      }
    },
    {
      name: t('amount'),
      sortable: 'count',
      allowOverflow: false,
      wrap: true,
      cell: row => (
        <JpText className={'ms-1'} type={'span-table-text'}>
          {row?.count.toString()}
        </JpText>
      )
    },
    {
      name: t('views.accounting.unitValue'),
      sortable: 'unit_value',
      cell: row => {
        return <>{row?.unit_value ? <JpCurrencyNumber value={row?.unit_value} /> : '-'}</>
      }
    },
    {
      name: t('views.accounting.totalValue'),
      sortable: 'total_value',
      wrap: false,
      cell: row => (row?.total_value ? <JpCurrencyNumber value={row?.total_value} /> : '-')
    }
  ]

  if (detailIndexIsLoading || invoicedChartIsLoading) {
    return <SpinnerLoader />
  }
  return (
    <>
      <BlueHeaderCard bodyClassName="m-0 p-0" headerContent={<HeaderCard />} bodyContent={<BodyCard />} />
      <div className="mt-1">
        <JpTableV1
          cols={tabCols}
          fetcher={handlePageChange}
          data={historicDetailData?.data}
          total={historicDetailData?.count}
          loading={historicDetailDataHasError || historicDetailDataIsLoading}
          actions={<> </>}
          footer={<TableFooter/>}
          footerClassname={'d-flex justify-content-end gap-50'}
        />
      </div>
    </>
  )
}

export default HistoricDetail
