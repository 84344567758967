// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PrivateRoute from '@components/routes/PrivateRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import MonthlyDetail from '@src/views/accounting/MonthlyDetail'
import HistoricDetail from '@src/views/accounting/HistoricDetail'
import ToBeInvoicedDetail from '@src/views/accounting/ToBeInvoicedDetail'
import AddServices from '@src/views/services/AddServices'
import EditServices from '@src/views/services/EditServices'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}
const ClientsTabsV2 = lazy(() => import('@src/views/Clients/ClientsTabsV2'))
// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/backoffice/home'

// ** Home Route
const Home = lazy(() => import('../../views/Home'))
// ** Profile Routes
const AccountSettings = lazy(() => import('../../views/pages/account-settings/index'))
// ** Settings Routes
const Settings = lazy(() => import('../../views/Settings'))
const AssignedRoles = lazy(() => import('../../views/Settings/AssignedRoles'))
const EditPermissions = lazy(() => import('../../views/Settings/EditPermissions'))
const SendInvitation = lazy(() => import('../../views/Settings/SendInvitation'))
const Error = lazy(() => import('../../views/Error'))
const SettingsProfile = lazy(() => import('../../views/Settings/profile-details'))
const Parameters = lazy(() => import('../../views/Settings/Parameters'))
const AppManagement = lazy(() => import('../../views/Settings/AppManagement'))

// ** Clients Routes
const Clients = lazy(() => import('../../views/Clients'))
const CreateClients = lazy(() => import('../../views/Clients/CreateClients'))
const EditClients = lazy(() => import('../../views/Clients/EditClient'))
const ClientsTab = lazy(() => import('../../views/Clients/ClientsTabs'))
const ClientLegalReview = lazy(() => import('@src/views/Clients/ClientLegalReview'))
const ClientProcessIndex = lazy(() => import('@src/views/Clients/ClientProcessIndex'))
// ** Contracts Routes
const Contracts = lazy(() => import('../../views/Contracts'))
const CreateTemplate = lazy(() => import('../../views/contracts/CreateTemplate'))
const EditTemplate = lazy(() => import('../../views/contracts/EditTemplate'))
const ContractDetail = lazy(() => import('../../views/contracts/ContractDetail'))
const SignContract = lazy(() => import('../../views/contracts/SignContract'))
const ContractorProfile = lazy(() => import('../../views/Contractors/Contractors-details/ContractorProfile'))
const PreviewDocument = lazy(() => import('@src/views/Contractors/Contractors-details/PreviewDocument'))
const PreviewContract = lazy(() => import('@src/views/Contractors/Contractors-details/PreviewContract'))
const AdminProfile = lazy(() => import('@src/views/AdminProfile/AdminProfile'))
const Contractors = lazy(() => import('../../views/Contractors'))
const PrevDoc = lazy(() => import('../../views/Contractors/Contractors-details/PreviewDocumentContract'))

// ** Payroll
const Payroll = lazy(() => import('../../views/Payrolls'))
const PayrollDetailTable = lazy(() => import('../../views/payrolls/PayrollDetailTable'))

// ** Accounting
const Accounting = lazy(() => import('@src/views/accounting/Accounting'))

// **Productivity
const Productivity = lazy(() => import('@src/views/Productivity'))
const EditApp = lazy(() => import('@src/views/productivity/EditApp'))
const EditAppChildren = lazy(() => import('@src/views/productivity/EditAppChildren'))
const CreateProfile = lazy(() => import('@src/views/productivity/CreateProfile'))
const EditProfile = lazy(() => import('@src/views/productivity/EditProfile'))

// ** Merge Routes
const Routes = [
  {
    path: '*',
    element: <Error />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/backoffice',
    index: true,
    element: <Navigate replace to={'clients'} /> //cuando se active el home, descomentar el path home y cambiar esto por:
    /* element: <Navigate replace to={DefaultRoute} /> */
  },
  /* {
    path: '/backoffice/home',
    element: <Home />,
    meta: {
      restricted: true
    }
  }, */
  {
    path: '/backoffice/settings',
    element: <Settings />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/settings/assigned-roles',
    element: <AssignedRoles />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/settings/assigned-roles/send-invite',
    element: <SendInvitation />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/settings/assigned-roles/permissions/:id',
    element: <EditPermissions />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/account-settings',
    element: <AccountSettings />
  },
  {
    path: '/backoffice/settings/assigned-roles/profile/:id',
    element: <SettingsProfile />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/settings/app',
    element: <AppManagement />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/clients',
    element: <Clients />
    /* meta: {
      restricted: true
    } */
  },
  {
    path: '/backoffice/clients/create',
    element: <CreateClients />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/clients/edit/:id',
    element: <EditClients />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/clients/detail/:id',
    element: <ClientsTabsV2 />,
    meta: {
      restricted: true
    }
  },
  /*   {
    path: '/backoffice/clients/validate/:id',
    element: <ClientLegalReview />,
    meta: {
      restricted: true
    }
  }, */
  {
    path: '/backoffice/contracts',
    element: <Contracts />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contracts/create',
    element: <CreateTemplate />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contracts/edit/:id',
    element: <EditTemplate />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contracts/contract/:id',
    element: <ContractDetail />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors/profile/admin/:id',
    element: <AdminProfile />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contracts/contract-sign/:id',
    element: <SignContract />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors/profile/:id',
    element: <ContractorProfile />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors/profile/:id/document-preview',
    element: <PreviewDocument />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors/profile/:id/contract-preview',
    element: <PreviewContract />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors',
    element: <Contractors />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/contractors/prevdoc/:id',
    element: <PrevDoc />
  },
  {
    path: '/backoffice/payroll',
    element: <Payroll />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/payroll/detail/:transaction_id',
    element: <PayrollDetailTable />,
    meta: {
      restricted: true
    }
  },
  {
    path: '/backoffice/accounting',
    element: <Accounting />
    /* meta: {
      restricted: true
    } */
  },
  {
    path: '/backoffice/accounting/monthly-detail/:client_id',
    element: <MonthlyDetail />
  },
  {
    path: '/backoffice/accounting/historic-detail/:invoice_id/:client_id',
    element: <HistoricDetail />
  },
  {
    path: '/backoffice/accounting/tobeinvoiced-detail/:id/:invoice_id',
    element: <ToBeInvoicedDetail />
  },
  {
    path: '/backoffice/clients/detail/services/create/:client_id',
    element: <AddServices />
  },
  {
    path: '/backoffice/clients/detail/services/:service_id/edit',
    element: <EditServices />
  },
  {
    path: '/backoffice/productivity',
    element: <Productivity />
  },
  {
    path: '/backoffice/productivity/apps/edit/:id',
    element: <EditApp />
  },
  {
    path: '/backoffice/productivity/apps/edit/:id/children',
    element: <EditAppChildren />
  },
  {
    path: '/backoffice/productivity/profiles/create/:id',
    element: <CreateProfile />
  },
  {
    path: '/backoffice/productivity/profiles/edit/:id',
    element: <EditProfile />
  },
  {
    path: '/backoffice/settings/parameters',
    element: <Parameters />
  },
  {
    path: '/backoffice/clients/inprocess/detail/:id',
    element: <ClientProcessIndex />
  }
]

const getRouteMeta = route => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter(route => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {
        const RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = layout => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach(layoutItem => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/backoffice',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
