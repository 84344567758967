import { combineReducers } from 'redux'
// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import settings from './settings'
import breadcrumbs from './breadcrumbs'
import getUrlPdf from './getUrlPdf'
import user from './user'
import routes from './routes'
import userInfo from './userInfo'

const rootReducer = combineReducers({ navbar, layout, settings, breadcrumbs, getUrlPdf, user, routes, userInfo })

export default rootReducer
