import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { JpCurrencyInput } from '@Intelli/utilities'

const JpCurrencyInputController = ({
  name,
  title,
  control,
  errors = {},
  disabled = false,
  optionsController = {},
  optionsInput = {},
  ns = 'errors',
  currency = 'US$'
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Controller
        name={name}
        control={control}
        {...optionsController}
        render={({ field }) => (
          <JpCurrencyInput
            title={title}
            name={name}
            disabled={disabled}
            optionsInput={{ ...optionsInput, ...field }}
            invalid={!!errors[name]}
            currency={currency}
          />
        )}
      />
      {errors[name] && <small className="text-danger">{t(errors[name]?.message, { ns })}</small>}
    </>
  )
}

export default JpCurrencyInputController
