import RepeatForm from '@src/components/services/RepeatForm'
import BackOfficeAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SpinnerLoader, SweetModal } from '@Intelli/utilities'

const EditServices = () => {
  const { t } = useTranslation()
  const API = new BackOfficeAPI()
  const navigate = useNavigate()
  const { client_id, service_id } = useParams()

  const { data: getServiceIndex, isLoading: getServiceIndexIsLoading, refetch: indexRefetch } = useQuery(
    ['getServiceIndex', service_id],
    () => API.getServiceIndex(service_id)
  )

  let dfValues = {
    repeater: [
      {
        service_type_id: { value: getServiceIndex?.service_type.id, label: getServiceIndex?.service_type?.name },
        count: getServiceIndex?.count,
        value: getServiceIndex?.unit_value,
        description: getServiceIndex?.description || ''
      }
    ]
  }

  // Edit service
  const { mutate: editService, isLoading: modalEditIsLoading } = useMutation(data => API.editService(data), {
    onSuccess: () => {
      indexRefetch(),
      SweetModal('success', t('SUCCESS'), t('views.services.successfulEdited'), t('Ok')), navigate(-1)
    }
  })

  const modalEdit = (service_id, services) =>
    SweetModal(
      'warning',
      t('views.services.editServices'),
      t('views.services.modalQuestionEditServices'),
      t('Ok'),
      result => {
        if (result.isConfirmed) {
          editService({
            service_id,
            services
          })
        }
      },
      { showCancelButton: true, cancelButtonText: t('CANCEL') }
    )

  if (getServiceIndexIsLoading || modalEditIsLoading) {
    return <SpinnerLoader />
  }

  return (
    <RepeatForm
      title={t('views.services.editServices')}
      client_id={client_id}
      service_id={service_id}
      defaultValues={dfValues}
      modalEdit={modalEdit}
    />
  )
}

export default EditServices
