import {
  BlueHeaderCard,
  JpText,
  JpOrdersBarChart,
  SpinnerLoader,
  JpCurrencyNumber,
  JpTableV1,
  JpAvatar,
  dateLocalizer,
  JpButton,
  JpFileModal,
  HasPermissionModules,
  SweetModal
} from '@Intelli/utilities'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import MainInfoHeader from '@src/components/accounting/MainInfoHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import BackOfficeAPI from '@src/services/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import JpInputController from '@src/components/input/JpInputController'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import moment from 'moment'

const ToBeInvoicedDetail = () => {
  const API = new BackOfficeAPI()

  const { t } = useTranslation()
  const { id, invoice_id } = useParams()

  const [currentPage, setCurrentPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [queries, setQueries] = useState('')
  const [sortBy, setSortBy] = useState('start_date__desc')
  const [isOpen, setIsOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [fileInvalid, setFileInvalid] = useState(false)

  const { modules } = useApplicationModules()

  const navigate = useNavigate();

  const hasWritePermission = HasPermissionModules.WriteUpdate(modules, ['ACCOUNTING', 'ACCOUNTING-INVOICING'])

  const documentsSchema = yup
    .object()
    .shape({
      invoice_number: yup.string().nullable().required('InputRequired')
    })
    .test(() => {
      if (files.length === 0) {
        setFileInvalid(true)
        return false
      }
      setFileInvalid(false)
      return true
    })
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      invoice_number: ''
    },
    resolver: yupResolver(documentsSchema)
  })

  const HeaderCard = () => {
    return (
      <div className="d-flex justify-content-between align-items-center col-12">
        <h4 className="text-white fw-bold mb-0">{t('Company')}</h4>
      </div>
    )
  }

  const {
    data: toBeInvoicedIndex,
    isError: toBeInvoicedHasError,
    isLoading: toBeInvoicedIsLoading
  } = useQuery(['toBeInvoicedTableIndex', invoice_id], () => API.toBeInvoicedIndex(invoice_id))

  const dataFromMainInfo = {
    logo: toBeInvoicedIndex?.client?.media?.location,
    name: toBeInvoicedIndex?.client?.name,
    country: toBeInvoicedIndex?.client?.address?.country,
    first_line: toBeInvoicedIndex?.client?.address?.first_line,
    second_line: '',
    email: toBeInvoicedIndex?.client?.email,
    phone: toBeInvoicedIndex?.client?.phone
  }

  const {
    data: toBeInvoicedData,
    isError: toBeInvoicedDataHasError,
    isLoading: toBeInvoicedDataIsLoading
  } = useQuery(
    ['toBeInvoicedData', invoice_id, currentPage, queries, limit, sortBy],
    () =>
      API.toBeInvoicedTable({
        query: queries,
        offset: currentPage,
        limit,
        order: sortBy,
        invoice_id: invoice_id
      }),
    { keepPreviousData: true }
  )

  const handlePageChange = ({ page, limit, query, sort }) => {
    setCurrentPage(page)
    setLimit(limit)
    setQueries(query)
    if (sort) setSortBy(sort)
  }

  const tabCols = [
    {
      id: 'avatar_column',
      name: '',
      sortable: false,
      compact: true,
      right: true,
      maxWidth: '50px',
      minWidth: '20px',
      cell: row => <JpAvatar icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />} color={`success`} />
    },
    {
      name: t('views.clients.createClient.service'),
      sortable: 'name',
      minWidth: '200px',
      compact: true,
      cell: row => {
        return (
          <p className="m-0">
            {row?.name}
            {row?.code && <span className="ms-50">{row?.code}</span>}
          </p>
        )
      }
    },
    {
      name: t('views.clients.createClient.date'),
      sortable: 'date',
      allowOverflow: false,
      wrap: true,
      cell: row => {
        return (
          <>
            {row?.date ? (
              <JpText type={'span-table-date'}>{dateLocalizer.getIntlLongDate(row?.date)}</JpText>
            ) : (
              '-'
            )}
          </>
        )
      }
    },
    {
      name: t('amount'),
      sortable: 'count',
      allowOverflow: false,
      wrap: true,
      cell: row => (
        <JpText className={'ms-1'} type={'span-table-text'}>
          {row?.count.toString()}
        </JpText>
      )
    },
    {
      name: t('views.accounting.unitValue'),
      sortable: 'unit_value',
      cell: row => {
        return <>{row?.unit_value ? <JpCurrencyNumber value={row?.unit_value} /> : '-'}</>
      }
    },
    {
      name: t('views.accounting.totalValue'),
      sortable: 'total_value',
      wrap: false,
      cell: row => (row?.total_value ? <JpCurrencyNumber value={row?.total_value} /> : '-')
    }
  ]

  const {
    data: invoicedChart,
    isError: invoicedChartHasError,
    isLoading: invoicedChartIsLoading
  } = useQuery(['invoicedChart', id], () => API.invoicedChart(id))

  const valuesChart = invoicedChart?.map(i => i.amount)
  const datesChart = invoicedChart?.map(i => moment(i.date).format('MMMM'))
  const transtaleDatesChart = datesChart?.map(i => t(`views.months.${i}`))

  const BodyCard = () => {
    return (
      <Row>
        <MainInfoHeader
          data={dataFromMainInfo}
          column={
            <Col className="ms-1 d-flex flex-column flex-lg-row">
              <div className="col-lg-8">
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('Payroll')}:</b>
                  </Col>
                  <Col>
                    {toBeInvoicedIndex?.payroll_balance ? (
                      <JpCurrencyNumber value={toBeInvoicedIndex?.payroll_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row className="d-flex mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.commissions')}:</b>
                  </Col>
                  <Col>
                    {toBeInvoicedIndex?.commission_balance ? (
                      <JpCurrencyNumber value={toBeInvoicedIndex?.commission_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.SASS')}:</b>
                  </Col>
                  <Col>
                    {toBeInvoicedIndex?.saas_balance ? (
                      <JpCurrencyNumber value={toBeInvoicedIndex?.saas_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.SERVICES')}:</b>
                  </Col>
                  <Col>
                    {toBeInvoicedIndex?.service_balance ? (
                      <JpCurrencyNumber value={toBeInvoicedIndex?.service_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row className="mb-50">
                  <Col>
                    <b className="fw-bolder text-primary me-75">{t('views.clients.totalInvoiced')}:</b>
                  </Col>
                  <Col>
                    {toBeInvoicedIndex?.total_balance ? (
                      <JpCurrencyNumber value={toBeInvoicedIndex?.total_balance} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
              </div>
              <JpOrdersBarChart
                title={t('views.accounting.totalBilled')}
                total={toBeInvoicedIndex?.total_balance}
                values={valuesChart}
                months={transtaleDatesChart}
                className={'p-0 ms-n1'}
              />
            </Col>
          }
        />
      </Row>
    )
  }

  const TableFooter = () => {
    return(
      <>
      <JpText type={'card-title'}>{t('views.clients.totalInvoiced')}</JpText>
      <span>:</span>
      <JpCurrencyNumber value={toBeInvoicedIndex?.total_balance} />
    </>
    )
  }

  const TabActions = () => {
    return (
      <div className="text-end mt-1 mt-md-0">
        <JpButton
          id="add"
          type="add"
          tooltip
          tooltipTextCustom={t('views.accounting.uploadInvoice')}
          options={{
            onClick: () => setIsOpen(true),
            textLess: true,
            iconPosition: 'center'
          }}
        />
      </div>
    )
  }

  const { mutate: uploadInvoice, isLoading: uploadInvoiceIsLoading } =
  useMutation(data => API.uploadInvoice(data), {
      onSuccess: () => {
          setFiles([])
          setIsOpen(false)
          navigate(-1)
          SweetModal(
              'success',
              t('Success'),
              t('views.accounting.successUpload'),
              t('Ok')
          )
      }
  })

  const onSubmit = data => {
    uploadInvoice({
      data: { file: files[0], number: data?.invoice_number.toString() },
      client_id: id,
      invoice_id: invoice_id
    })
  }

  if (toBeInvoicedHasError || toBeInvoicedIsLoading || uploadInvoiceIsLoading || invoicedChartIsLoading) {
    return <SpinnerLoader />
  }

  return (
    <>
      <JpFileModal
        isOpen={isOpen}
        headerContent={<h4 className='m-0'>{t('views.accounting.uploadInvoice')}</h4>}
        bodyContent={
          <div className="my-1">
            <JpInputController
              name="invoice_number"
              control={control}
              title={t('views.accounting.invoiceChargeTitle')}
              errors={errors}
              optionsInput={{
                placeholder: t('views.accounting.invoiceNumberInfo')
              }}
            />
          </div>
        }
        bodyPosition="top"
        onSubmit={handleSubmit(onSubmit)}
        toggle={() => {
          reset(), setFiles([]), setIsOpen(false)
        }}
        files={files}
        setFiles={setFiles}
        fileInvalid={fileInvalid}
        setFileInvalid={setFileInvalid}
      />
      <BlueHeaderCard bodyClassName="m-0 p-0" headerContent={<HeaderCard />} bodyContent={<BodyCard />} />
      <div className="mt-1">
        <JpTableV1
          cols={tabCols}
          fetcher={handlePageChange}
          data={toBeInvoicedData?.data}
          total={toBeInvoicedData?.count}
          loading={toBeInvoicedDataHasError || toBeInvoicedDataIsLoading}
          actions={
            hasWritePermission ? <TabActions /> : <></>
          }
          footer={<TableFooter/>}
          footerClassname={'d-flex justify-content-end gap-50'}
        />
      </div>
    </>
  )
}

export default ToBeInvoicedDetail
