// ** Router imports
import { useRoutes } from 'react-router-dom'

// ** GetRoutes
import { getRoutes } from './routes'

// ** Hooks Imports
import { useLayout } from '@hooks/useLayout'
import { addRoutes } from '@src/redux/routes'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import BackOfficeAPI from '@src/services/API'
import { setuserInfo } from '@src/redux/userInfo'

const Router = () => {
  // ** Hooks
  const { layout } = useLayout()
  const dispatch = useDispatch()
  const API = new BackOfficeAPI()

  const allRoutes = getRoutes(layout)

  const availablePaths = []

  for (const route of allRoutes) {
    if (route.children) {
      for (const child of route.children) {
        availablePaths.push(child.path)
      }
    } else {
      availablePaths.push(route.path)
    }
  }

  dispatch(addRoutes(availablePaths))

  const {
    data: userData,
    isError: userHasError,
    isLoading: userIsLoading,
    isSuccess: userDataSuccess
  } = useQuery(['user'], () => API.getUser()
    
  )

  useEffect(() => {
    if (userDataSuccess) {
      dispatch(setuserInfo(userData))
    }
  }, [userDataSuccess])

  const routes = useRoutes([...allRoutes])

  return routes
}

export default Router
